/**
 * @namespace GeneralUtils
 */

/**
 * A catchphrase for the application.
 * @memberof GeneralUtils
 * @type {string}
 * @name CATCHPHRASE
 */
export const CATCHPHRASE =
  "... cloud-based data sharing, visualization, and analysis ...";

/**
 * A loading page message.
 * @memberof GeneralUtils
 * @type {string}
 * @name LOADINGPAGE
 */
export const LOADINGPAGE = "... loading web page ...";

/**
 * Wraps a promise or a value to return an array with the error and data.
 * @function to
 * @memberof GeneralUtils
 * @param {Promise|any} promise - The promise to wrap or a value.
 * @returns {Promise<Array>} A promise that resolves to an array with the error and data.
 */
export const to = (promise) => {
  // Check if the input is a Promise (not limited to async functions)
  if (
    typeof promise?.then === "function" &&
    typeof promise?.catch === "function"
  ) {
    return promise.then((data) => [null, data]).catch((err) => [err]);
  }

  // Handle non-promise values
  return Promise.resolve([null, promise]);
};

/**
 * Converts a dictionary object to an array of objects with name and value properties.
 * @function dict2array
 * @memberof GeneralUtils
 * @param {Object} d - The dictionary object.
 * @returns {Array<Object>} An array of objects with name and value properties.
 */
export const dict2array = (d) => {
  return Object.entries(d).map(([key, value]) => ({ name: key, value: value }));
};

/**
 * Parses a JSON string and returns an array with the error and parsed object.
 * @function parseJson
 * @memberof GeneralUtils
 * @param {string} value - The JSON string to parse.
 * @returns {Array} An array with the error and parsed object.
 */
export const parseJson = (value) => {
  try {
    const json = JSON.parse(value);
    return [null, json];
  } catch (e) {
    return [e];
  }
};

/**
 * Removes keys with a specific prefix from an object.
 * @function removeKeysWithPrefix
 * @memberof GeneralUtils
 * @param {Object} obj - The object to modify.
 * @param {string} prefix - The prefix to match.
 * @returns {Object} The modified object.
 */
export const removeKeysWithPrefix = (obj, prefix) => {
  for (const key in obj) {
    if (key.startsWith(prefix) && key !== prefix) {
      delete obj[key];
    }
  }
  return obj;
};
