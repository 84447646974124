import { filesize } from "filesize";

/**
 * @namespace FileUtils
 */

/**
 * Checks if a given S3 key represents a folder.
 * @function isFolder
 * @memberof FileUtils
 * @param {string} [Key="/"] - The S3 key.
 * @returns {boolean} True if the key represents a folder, false otherwise.
 */
export const isFolder = (Key = "/") => Key.endsWith("/");

/**
 * Converts an S3 list to a tree structure.
 * @function S3List2Tree
 * @memberof FileUtils
 * @param {Object} tree - The S3 list object.
 * @returns {Array<Object>} The tree structure array.
 */
export const S3List2Tree = (tree) => {
  return Object.keys(tree).map((key) => ({
    id: key,
    parent: tree[getFolderKey(key)] ? getFolderKey(key) : 0,
    droppable: isFolder(key),
    text: getKeyBasename(key),
    data: tree[key],
  }));
};

/**
 * Get the basename of a path without its extension.
 * @function getBasenameWithoutExtension
 * @memberof FileUtils
 * @param {string} Key - The file path.
 * @returns {string} The basename without the extension.
 */
export const getBasenameWithoutExtension = (Key = "") => {
  const basename = (Key || "").split("/").pop();
  const parts = basename.split(".");
  return parts.length > 1 ? parts.slice(0, -1).join(".") : basename;
};

/**
 * Get the key without its extension.
 * @function geKeyWithoutExtension
 * @memberof FileUtils
 * @param {string} Key - The file path.
 * @returns {string} The key without the extension.
 */
export const geKeyWithoutExtension = (Key = "") => {
  const basename = (Key || "").split("/").pop();
  const parts = basename.split(".");
  const name = parts.length > 1 ? parts.slice(0, -1).join(".") : basename;

  return name == "" ? getFolderKey(Key) : getFolderKey(Key) + name;
};

/**
 * Get the basename of a path.
 * @function getKeyBasename
 * @memberof FileUtils
 * @param {string} Key - The file path.
 * @returns {string} The basename of the path.
 */
export const getKeyBasename = (Key = "") => {
  const cleanedPath = (Key || "").replace(/\/$/, "");
  const parts = cleanedPath.split("/");
  return parts[parts.length - 1];
};

/**
 * Gets the folder key from a given S3 key.
 * @function getFolderKey
 * @memberof FileUtils
 * @param {string} [Key=""] - The S3 key.
 * @returns {string|undefined} The folder key or undefined if the key is empty.
 */
export const getFolderKey = (Key = "") => {
  const parts = Key.split("/");
  const nonEmptyParts = parts.filter((part) => part !== "");
  nonEmptyParts.pop();
  const folderKey = nonEmptyParts.join("/");
  return folderKey == "" ? undefined : folderKey + "/";
};

/**
 * Gets the current folder from a given S3 key.
 * @function getCurrentFolder
 * @memberof FileUtils
 * @param {string} [Key=""] - The S3 key.
 * @returns {string} The current folder key.
 */
export const getCurrentFolder = (Key = "") =>
  Key.endsWith("/") ? Key : getFolderKey(Key);

/**
 * Checks if a string has a file extension.
 * @function hasFileExtension
 * @memberof FileUtils
 * @param {string} str - The string to check.
 * @returns {boolean} True if the string has a file extension, false otherwise.
 */
export const hasFileExtension = (str) => {
  const extensionPattern = /\.[0-9a-z]+$/i;
  return extensionPattern.test(str);
};

/**
 * Changes the file extension of a given file path.
 * @function changeFileExtension
 * @memberof FileUtils
 * @param {string} filePath - The file path.
 * @param {string} newExtension - The new file extension.
 * @returns {string|undefined} The file path with the new extension or undefined if the file path is empty.
 */
export const changeFileExtension = (filePath, newExtension) => {
  if (!filePath) return;
  return filePath.replace(/\.[^/.]+$/, "." + newExtension);
};

/**
 * Creates an image URL from binary data.
 * @function createImageURL
 * @memberof FileUtils
 * @param {ArrayBuffer|Blob} binaryData - The binary data.
 * @param {string} type - The MIME type of the image.
 * @returns {string} The object URL for the image.
 */
export const createImageURL = (binaryData, type) => {
  const blob = new Blob([binaryData], { type });
  return URL.createObjectURL(blob);
};

/**
 * Formats a file size in bytes to a human-readable string.
 * @function fileSize
 * @memberof FileUtils
 * @param {number} size - The file size in bytes.
 * @returns {string} The formatted file size.
 */
export const fileSize = (size) =>
  size && filesize(size, { base: 2, standard: "jedec" });

/**
 * Gets the file extension from a given file path.
 * @function getFileExtension
 * @memberof FileUtils
 * @param {string} path - The file path.
 * @returns {string|null} The file extension or null if the path is empty or has no extension.
 */
export const getFileExtension = (path) => {
  if (!path) return null;
  const parts = path.split(".");
  if (parts.length === 1 || (parts[0] === "" && parts.length === 2)) {
    return null;
  }
  return parts.pop().toLowerCase();
};

/**
 * Splits a raw URL into its path and query components.
 * @function splitPathAndQuery
 * @memberof FileUtils
 * @param {string} raw - The raw URL.
 * @returns {Object} An object containing the path and query components.
 */
export const splitPathAndQuery = (raw) => {
  const [path, query = ""] = raw.split("?");
  const queryObject = {};

  if (query) {
    const params = new URLSearchParams(query);
    for (const [key, value] of params.entries()) {
      queryObject[key] = value;
    }
  }

  return {
    path,
    query: queryObject,
  };
};

/**
 * Gets the relative path from a given S3 key and prefix.
 * @function getRelativePath
 * @memberof FileUtils
 * @param {string} Key - The S3 key.
 * @param {string} Prefix - The prefix to remove from the key.
 * @returns {string} The relative path.
 */
export const getRelativePath = (Key, Prefix) => Key.substring(Prefix.length);
