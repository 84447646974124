import dayjs from "dayjs";

/**
 * @namespace DateUtils
 */

/**
 * Converts a date object to a local date string in the format "YYYY-MM-DD HH:mm:ss A".
 * @function dateToLocal
 * @memberof DateUtils
 * @param {Date|string|number} o - The date object, string, or timestamp to convert.
 * @returns {string|null} The formatted date string or null if the input is invalid.
 */
export const dateToLocal = (o) => o && dayjs(o).format("YYYY-MM-DD HH:mm:ss A");

export const dateToUTC = (o) =>
  o && dayjs(o).add(-dayjs().utcOffset(), "minute");

/**
 * A custom reviver function for JSON.parse to convert numeric values to Date objects.
 * @function dateReviver
 * @memberof DateUtils
 * @private
 * @param {string} key - The key of the property being parsed.
 * @param {*} value - The value of the property being parsed.
 * @returns {*} The parsed value, converted to a Date object if it is a number.
 */
const dateReviver = (key, value) =>
  typeof value === "number" ? new Date(value) : value;

/**
 * Parses a JSON string, converting numeric values to Date objects using a custom reviver function.
 * @function JSONparseDate
 * @memberof DateUtils
 * @param {string} o - The JSON string to parse.
 * @returns {Object} The parsed object with Date objects for numeric values.
 */
export const JSONparseDate = (o) => JSON.parse(o, dateReviver);
