import React from "react";
import { useNavigate as _useNavigate } from "react-router-dom";

/**
 * @namespace ReactHooks
 */

/**
 * Custom hook to use the navigate function from react-router-dom.
 * @function useNavigate
 * @memberof ReactHooks
 * @returns {function|null} The navigate function or null if an error occurs.
 */
export const useNavigate = () => {
  try {
    return _useNavigate();
  } catch (error) {
    return null;
  }
};

/**
 * Custom hook to store the previous value of a state or prop.
 * @function usePrevious
 * @memberof ReactHooks
 * @param {any} value - The current value to store.
 * @returns {any} The previous value.
 */
export const usePrevious = (value) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
};
export default usePrevious;

/**
 * Custom hook to parse query parameters from the location object.
 * @function useQueryParams
 * @memberof ReactHooks
 * @param {Object} [location={}] - The location object containing the search string.
 * @returns {Object} An object containing the parsed query parameters.
 */
export const useQueryParams = (location = {}) => {
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const paramsObject = {};

  queryParams.forEach((value, key) => {
    paramsObject[key] = value;
  });

  return paramsObject;
};
